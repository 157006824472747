const propertyData = {
    "url": "http://http://15082nwfiglane.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "15082 NW FIG LN", "city": "Portland", "state":"Oregon", "zipcode": "97229"},
    "display_address": "15082 NW FIG LN",
    "property": {
      "listing_price": "$409,888",
      "bedrooms": "3",
      "baths": "3.5",
      "sq_feet": "1625",
      "lot_size": "1742",
      "rmls": "20125095",
      "built_in": "2018",
      "blurb": "Beautiful end unit luxury townhome in desirable Bethany neighborhood! Floor plan perfect for entertaining. Open kitchen features granite counters, ss appliances, massive island seats 6 comfortably. Living room w/gas fireplace & slider to large covered deck. Lower level guest suite. Bonus space for office/home school. Finished garage. New A/C. Recreation include acres of natural open space with 5 parks, sport courts & walking paths. Excellent schools. Warranty until 12/2021.",
      "ammenities": [

      ]
    },
    "image_count": 21,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.019971607798!2d-122.83460668443897!3d45.5700369791023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5495062374eaa299%3A0x99f1d352dd48c761!2s15082%20NW%20Fig%20Ln%2C%20Portland%2C%20OR%2097229!5e0!3m2!1sen!2sus!4v1601581589917!5m2!1sen!2sus",
        "youtube":"8zp4adAOQ5k",
        "matterport":"6tpK1y6P5ph"
    }
  }

export default propertyData;

